import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="container max">
      <div className="row py-5"
        style={{
          minHeight: 'calc(-228px + 100vh)'
        }}>
        <div className="col-md-8">
          <h2 className="mb-5">PRIVACY POLICY</h2>
          <h3 className="pt-5">Our Commitment To Privacy</h3>
          <p>Your privacy is important to us. This Privacy Policy (“Policy”) explains how DiversiTech (referred to hereafter as “we,” “us,” “our,” “DV” or “DV”) handle your personal information when you use our website (<a href="http://www.DiversiTech.com" target="_blank" rel="noreferrer noopener">http://www.DiversiTech.com</a>), applications and services (referred to hereafter collectively as “Website”). When you use the Website, you expressly consent to our collection, storage, use and disclosure of your personal information as described in this Policy.</p>
          <ol>
            <li>
              <h5>Collection of Personal Information.</h5>
              <p>We do not automatically collect personal information when you browse the Website. We only collect personally identifiable information, such as your name, address, telephone number, email address, credit card information and date of birth when you specifically and knowingly provide it to us by registering to create an account, placing an order, completing online forms, opting in to receive our newsletter and promotional offers or entering a sweepstakes, contest or drawing. When you use the website to apply for employment opportunities, we may collect additional personal information such as your resume. Information that you furnish to DiversiTech is used to provide the services available through the Website.</p>
            </li>
            <li>
              <h5>Automatically-Collected Information.</h5>
              <p>We automatically collect non-personal information about the computer or devices (including mobile devices) you use to access the Website. For example, we may collect and store information such as your browser type, IP address, language, operating system, location of your wireless device (e.g. latitude and longitude), the state or country from which you accessed the Website, unique device identifier (e.g. a UDID or IDFA on Apple devices), the pages you view, the services you use, the date and time of your visit, the websites you visited immediately before and after visiting the Website, error logs and other hardware and software information. This automatically collected information does not identify and is not associated with any specific individuals. We may use third party analytics providers and technologies, including cookies and similar tools to assist in collecting this information. We may use this information to formulate statistical models about use of the Website, enhance the services available through the Website for other users, and to provide you with tailored content and advertising.</p>
            </li>
            <li>
              <h5>Use of Your Information.</h5>
              <p>We use your information for the purpose for which you provided it, and we may also use your information for other purposes such as to:</p>
              <ul>
                <li>Create and maintain your account or registration;</li>
                <li>Enhance the user experience;</li>
                <li>Perform research and analytics;</li>
                <li>Customize and personalize the content and advertising that you see on the Website;</li>
                <li>Respond to and fulfill your request for services (including processing orders);</li>
                <li>Determine your eligibility for certain promotions, contests and services; and</li>
                <li>Send you communications related to your account and to alert you of the latest developments and features of the Website.</li>
              </ul>
              <p>We use information your provide about someone else when placing an order only to ship the product to that person and to confirm delivery.We do not share this information with outside parties except to the extent necessary to complete that order.</p>
            </li>
            <li>
              <h5>Disclosure of Your Information to Third Parties.</h5>
              <p>We do not disclose your personal information to any 3rd parties.</p>
            </li>

            <li>
              <h5>Use of Cookies and Other Technology.</h5>
              <p>The Website uses “cookie” technology and similar online tools, such as web beacons and web pixels. “Cookies” are small files that a website stores on a user’s computer or device. The Website uses Cookies to keep the information you enter on multiple pages together. The majority of the cookies that we use are “session cookies” that are automatically deleted from your hard drive after you close your browser at the end of your session. Session cookies are used to optimize performance of the Website and to limit the amount of redundant data that is downloaded during a single session. DiversiTech also uses “persistent cookies” which remain on your computer or device unless deleted by you (orby your browser’s settings). We use persistent cookies for statistical analysis of the Website’s performance to ensure the ongoing quality of the services provided through the Website. We do not use cookies to obtain or retain any personal information about you apart from what you voluntarily provide us. Most web browsers automatically accept cookies, but web browsers can be set to block cookies (consult your browser’s instructions on how to block cookies). Please note that blocking cookies may interfere with your ability to perform certain transactions, use certain functionality, and access certain content on the Website.</p>
            </li>
            <li>
              <h5>User-Initiated Communication.</h5>
              <p>From time to time, portions of the Website may enable you to send emails and other types of messages to us or third parties. All such, email,messages and written communications become our property once you submit them. Whenever you choose to initiate these kinds of communications with us, you may be contacted in return.</p>
            </li>
            <li>
              <h5>Opting In and Out of Emails and Promotional Communications.</h5>
              <p>To receive newsletters and other promotional communications by email, users may voluntarily sign up on the Website. From time to time, we may send you email notices or news updates alerting you to new features, products, promotions, services pertaining to our offerings, or other topics that may be of interest to you. If you no longer wish to receive promotional materials from us, you may opt out of receiving these communications by following the instructions included in each newsletter or communication or by emailing us at <a href="mailto:info@DiversiTech.com">info@DiversiTech.com</a> please note that these opt-out processes take some time to complete, consistent with applicable law.</p>
            </li>
            <li>
              <h5>Data Security.</h5>
              <p>We follow generally accepted industry standards to protect the personally identifiable information that we receive from you, while the information is being transmitted and once we receive it. When you provide us with sensitive information (such as credit card information) on our order forms, we encrypt the information using secure socket layer technology, which is commonly referred to as “SSL.” However, despite our best efforts, no method of transmission over the internet, or method of electronic storage is 100% secure. We encourage you to contact us immediately if you believe that any personally identifiable information that you have provided us is being used contrary to this Policy. </p>
            </li>
            <li>
              <h5>Privacy of Children.</h5>
              <p>Protecting the privacy of children is especially important to us. We do not knowingly market, collect or maintain any personal information from children under the age of 13. If a parent or guardian believes that we have personal information of a chiDV under the age of 13 in our database, they shouDV contact us immediately at <a href="mailto:info@DiversiTech.com">info@DiversiTech.com</a> will promptly remove such information from our records.</p>
            </li>
            <li>
              <h5>Accessing or Correcting Your Information.</h5>
              <p>If your personally identifiable information changes, is inaccurate, or if you no longer wish to use the services offered through the Website, you may notify us by emailing our customer support at <a href="mailto:info@DiversiTech.com">info@DiversiTech.com</a> or by contacting us by telephone or mail at the contact information listed below. You may request factual errors to be corrected in your personally identifiable information by sending us a request that credibly shows the errors. To protect your privacy and security, we will take reasonable steps to verify your identity before granting access or making corrections.</p>
              <p>We will retain your information for as long as your account is active or as needed to provide you services. If you wish to cancel your account or request that we no longer use your information to provide you services contact us at <a href="mailto:info@DiversiTech.com">info@DiversiTech.com</a>. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
            </li>
            <li>
              <h5>Third Party Links and Services.</h5>
              <p>The Website may contain links to third-party websites including social networking websites. Your use of these features may result in the collection or sharing of information about you, depending on the feature. Please be aware that we are not responsible for the content or privacy practices of other websites or services to which we link. We do not endorse or make any representations about third-party websites or services. The personal information you choose to provide to such third parties, or that is collected by them, is not covered by this Policy. We strongly encourage you to read such third parties’ privacy policies.</p>
            </li>
            <li>
              <h5>Contact Us.</h5>
              <p>If you have any questions about the Policy, contact us by sending an email to <a href="mailto:info@DiversiTech.com">info@DiversiTech.com</a>. You can also call us at (800) 995-2222 or write to us at:</p>
              <p>DiversiTech<br />
                3039 Premiere Parkway<br />
                Duluth, GA 30097</p>
              <p>We may record the conversations that our agents or employees have with you. We do this from time to time to monitor the quality of service and accuracy of information our agents and employees give to you and to ensure that your instructions are followed. No agent or employee of the Company is authorized to waive any provisions of any of DiversiTech’ policies or procedures.</p>
            </li>
            <li>
              <h5>Updates to Policy.</h5>
              <p>We may amend this Policy at any time by posting the changes on this page. Any changes to the Policy will become effective upon posting. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this Site prior to the change becoming effective. Your continued use of the Website following any changes means you accept such revisions. You should check the effective date of the Policy below and review any changes since the last time you used the website.</p>
            </li>
            <li>
              <h5>Effective Date.</h5>
              <p>This Policy is effective as of June 23rd 2020.</p>
            </li>
          </ol>
        </div>
      </div>
    </div>

  </Layout>
)

export default PrivacyPolicy
